import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import AssessmentsRegister from "../pages/billing/AssessmentsRegister";
import GenericTabView from "./tabs/GenericTabView";
import { STATUS_APPROVED } from "../../constants/webapp";

const GenericView = ({
  viewData,
  navLinks,
  titleLink,
  children,
  setShowAuditTable,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const tax = location?.state?.tax;

  const DetailSection = ({ data, title, component }) => {
    if (data.sections) {
      const filteredData = Object.keys(data).filter(
        (key) => key !== "sections"
      );
      if (!filteredData.length) return null;
    }

    return (
      <>
        {component ? (
          component
        ) : (
          <div className="section">
            {title && <h4>{title}</h4>}
            <table
              id="w1"
              className="table table-striped table-bordered detail-view"
              style={{ marginBottom: 0 }}
            >
              <tbody>
                {Object.keys(data).map((key) => {
                  if (key === "sections") return null;
                  return (
                    <tr key={key}>
                      <th style={{ textTransform: "capitalize" }}>
                        {String(key).replaceAll("_", " ")}
                      </th>
                      <td>{data[key] || "Empty"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  const DetailsComponent = () => (
    <div id="content">
      <div className="outer">
        <div className="inner bg-light lter">
          {titleLink && (
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to={titleLink.link}>{titleLink.title}</Link>
              </li>
              {viewData?.name ? (
                <li className="active"> Details for {viewData?.name}</li>
              ) : null}
            </ul>
          )}
          <div className="trade-license-view container-fluid">
            <div className="row">
              <div className="col-sm-6">
                {viewData?.id && <h3> ID: {viewData?.id}</h3>}
              </div>
              <div
                className="col-sm-6"
                style={{
                  display: "block",
                  float: "left",
                  // marginTop: 15,
                  marginBottom: 15,
                  position: "relative",
                }}
              >
                {navLinks &&
                  navLinks.map((item) => {
                    if (item.visibility) {
                      return (
                        <Link
                          className="btn btn-primary"
                          to={item.link}
                          style={{ marginRight: 10 }}
                        >
                          {item.title}
                        </Link>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {viewData.sections?.map((section, index) => {
                if (section?.visible === false) return null;
                return (
                  <DetailSection
                    key={index}
                    data={section.fields}
                    title={section.title}
                    component={section.component}
                  />
                );
              })}
              {/* if sections is not defined, show the data */}
              {!viewData.sections && <DetailSection data={viewData} />}
            </div>
          </div>
          <div id="react-content"> </div>
          {children}
        </div>
      </div>
    </div>
  );

  const [taxTabs, setTaxTabs] = useState([
    {
      title: "Details",
      component: <DetailsComponent />,
    },
  ]);

  useEffect(() => {
    if (viewData?.Status?.toLowerCase() === STATUS_APPROVED) {
      setTaxTabs([
        ...taxTabs,
        {
          title: "Assessments",
          component: (
            <AssessmentsRegister search={`?tax_id=${id}&tax=${tax}`} />
          ),
        },
      ]);
    }
  }, [viewData?.Status]);

  return (
    <div>
      {tax ? (
        <GenericTabView tabs={taxTabs} setShowAuditTable={setShowAuditTable} />
      ) : (
        <DetailsComponent />
      )}
    </div>
  );
};

GenericView.propTypes = {
  viewData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
  }),
  navLinks: PropTypes.arrayOf({}),
  children: PropTypes.node,
  titleLink: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default GenericView;
