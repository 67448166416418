import {
  Description,
  Logout,
  Person,
  Settings,
  Paid,
  Business,
  BusinessCenter,
  Work,
  Hotel,
  Stars,
  Place,
  Group,
  Password,
  AddBusiness,
  PrecisionManufacturingOutlined,
  CorporateFare,
  Streetview,
  LocalParking,
  Route,
  EmojiTransportation,
  Landscape,
  Approval,
  Apartment,
  Inventory,
  CreditScore,
  CurrencyPound,
  AccountBalance,
  Article,
  Inventory2,
  House,
  ListSharp,
  LocalActivityOutlined,
  LocalGasStationRounded,
  CardTravel,
  AdminPanelSettings,
  AssignmentTurnedIn,
  MinorCrash,
  Subway,
  Commute,
  Percent,
  Receipt,
  Architecture,
  AutoStories,
  Newspaper,
  Diversity2,
} from "@mui/icons-material";

import {
  CalculatorOutlined,
  UserOutlined,
  CreditCardOutlined,
  ShopFilled,
  FileDoneOutlined,
} from "@ant-design/icons";

import UserProfile from "../components/pages/users/UserProfile";
import TradingLicenseDetails from "../components/pages/trade-license/TradeLicenseDetails";
import AssessmentsRegister from "../components/pages/billing/AssessmentsRegister";
import {
  FaBorderNone,
  FaClock,
  FaSlackHash,
  FaWarehouse,
} from "react-icons/fa";
import InvoicesOwner from "../components/pages/billing/InvoicesOwner";
import { PaymentsPublicUser } from "../components/pages/payments/PaymentsPublicUser";
import GeneralLedgerRegister from "../components/pages/payments/GeneralLedgerRegister";
import {
  sDevelopmentControl,
  sPayments,
  sRevenueSources,
} from "../components/layouts/LeftMenu";
import ArrearsLedgerRegister from "../components/pages/payments/ArrearsLedgerRegister";
import RevenueLedgerRegister from "../components/pages/payments/RevenueLedgerRegister";
import BusinessBranchRegister from "../components/pages/payments/BusinessBranchRegister";
import SetInstantTinForm from "../components/pages/instant-tin/SetInstantTinForm";
import { GetPermissions } from "../utils/permissionsUtils";
import {
  GetPermissions as GetPermissionRoles,
  IsPropertyTaxAdmin,
  HasUserType
} from "../utils/authUtils";
import {
  CAN_ADMIN,
  CAN_DIVISION,
  CAN_MUNICIPALITY,
  APP_AUTHORITY,
  MARKET_ADMIN, PUBLIC_APP_USER
} from "./webapp";

export var settings = () => [
  {
    title: "Access Control",
    Icon: <Group />,
    visible: GetPermissions([CAN_ADMIN]),
    children: [
      {
        title: "User Management",
        Icon: <Settings />,
        link: "/users",
      },
      {
        title: "Roles",
        Icon: <ListSharp />,
        link: "/roles",
      },
      {
        title: "Change Password",
        Icon: <Settings />,
        link: "/change-password",
      },

      {
        title: "Audit Trail",
        Icon: <Settings />,
        link: "/audit/activity",
      },
    ],
  },

  {
    title: "Master Data",
    visible: GetPermissions([CAN_ADMIN]),
    Icon: <Description />,
    link: "/",
    children: [
      {
        title: "Municipalities",
        Icon: <Place />,
        link: "/local-governments",
      },
      {
        title: "Divisions",
        Icon: <Place />,
        link: "/divisions",
      },
      {
        title: "Parishes",
        Icon: <Place />,
        link: "/parishes/",
      },
      {
        title: "Villages",
        Icon: <Place />,
        link: "/villages/",
      },
      {
        title: "Streets",
        Icon: <Streetview />,
        link: "/streets/",
      },

      {
        title: "Vote Codes",
        Icon: <Streetview />,
        link: "/vote-codes/",
      },
    ],
  },

  {
    title: "Business Category",
    visible: GetPermissions([CAN_ADMIN]),
    Icon: <Business />,
    link: "/",
    children: [
      {
        title: "Business Categories",
        Icon: <Work />,
        link: "/business-categories",
      },
      {
        title: "Nature Of Business",
        Icon: <BusinessCenter />,
        link: "/nature-of-business",
      },

      {
        title: "Business Category Amount",
        Icon: <Work />,
        link: "/category-amount",
      },
    ],
  },

  {
    title: "Operational Permit",
    visible: GetPermissions([CAN_ADMIN]),
    Icon: <Business />,
    link: "/",
    children: [
      {
        title: "Operational Permit Categories",
        Icon: <Work />,
        link: "/operational-permit-categories",
      },
      {
        title: "Operational Permit Sub Categories",
        Icon: <BusinessCenter />,
        link: "/operational-permit-sub-categories",
      },
    ],
  },

  {
    title: "Configurables / Dropdowns",
    Icon: <PrecisionManufacturingOutlined />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Update Contact For Local Government",
        Icon: <Diversity2 />,
        link: "/mobile-contacts-configure/create",
      },
      {
        title: "Walls",
        Icon: <Work />,
        link: "/walls",
      },
      {
        title: "Wall Finish",
        Icon: <BusinessCenter />,
        link: "/finish-walls",
      },
      {
        title: "Building Types",
        Icon: <Apartment />,
        link: "/building-types",
      },
      {
        title: "Construction Completion Status",
        Icon: <Apartment />,
        link: "/construction-completion",
      },
      {
        title: "Business Categories",
        Icon: <Business />,
        link: "/business-category",
      },
      {
        title: "Rent Payment Interval",
        Icon: <House />,
        link: "/rent-payment-interval",
      },

      {
        title: "Lease Status",
        Icon: <BusinessCenter />,
        link: "/lease-status",
      },

      {
        title: "Land Title",
        Icon: <BusinessCenter />,
        link: "/land-title",
      },
      {
        title: "Tender Validity",
        Icon: <Work />,
        link: "/tender-validity",
      },
      {
        title: "Payment Interval",
        Icon: <Work />,
        link: "/payment-intervals",
      },
      {
        title: "Objection Reasons",
        Icon: <Work />,
        link: "/objections",
      },
      {
        title: "Building Condition",
        Icon: <House />,
        link: "/building-condition",
      },
      {
        title: "Application Fees",
        Icon: <Receipt />,
        link: "/application-fees",
      },
      {
        title: "Waste Fees",
        Icon: <Receipt />,
        link: "/waste-fees",
      },
      {
        title: "Sign Fees",
        Icon: <Receipt />,
        link: "/sign-fees",
      },
    ],
  },

  // TAX HEADS
  {
    title: "Tax Heads",
    Icon: <AutoStories />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/tax-head",
  },

  // NEWS
  {
    title: "News Section",
    Icon: <Newspaper />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/news/register",
  },

  {
    title: "Rates",
    Icon: <CorporateFare />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "LHT Rates",
        Icon: <CorporateFare />,
        link: "/lht-rates",
      },
      {
        title: "LST Rates",
        Icon: <CorporateFare />,
        link: "/lst-rates",
      },
      {
        title: "Annual Rates",
        Icon: <CorporateFare />,
        link: "/annual-rates",
      },
      {
        title: "Planning Rates",
        Icon: <CorporateFare />,
        link: "/planning-rates",
      },
      {
        title: "Property Annual Rates",
        Icon: <CorporateFare />,
        link: "/property-annual-rates",
      },
      {
        title: "Self-employment Rates",
        Icon: <CorporateFare />,
        link: "/self-employment-rates",
      },
      {
        title: "Lettable Area Percentages",
        Icon: <Percent />,
        link: "/lettable-area-percentages",
      },
      {
        title: "Rateable Value Percentages",
        Icon: <Percent />,
        link: "/rateable-value-percentages",
      },
      {
        title: "Gross Value Percentages",
        Icon: <Percent />,
        link: "/gross-value-percentages",
      },
    ],
  },

  {
    title: "Local Service Tax",
    Icon: <CorporateFare />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "LST Categories",
        Icon: <CorporateFare />,
        link: "/lst-categories",
      },
      {
        title: "Nature of Business",
        Icon: <CorporateFare />,
        link: "/lst-nature-of-business",
      },
    ],
  },

  {
    title: "Markets",
    Icon: <CorporateFare />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Markets",
        Icon: <CorporateFare />,
        link: "/markets",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Nature of Market",
        Icon: <CorporateFare />,
        link: "/nature-of-market",
        visible: GetPermissions([CAN_ADMIN]),
      },

      {
        title: "Facility Types",
        Icon: <CorporateFare />,
        link: "/market-facility-types",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Market Grades",
        Icon: <CorporateFare />,
        link: "/market-facilities",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Market due categories",
        Icon: <Hotel />,
        link: "market-due-categories",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Market dues items",
        Icon: <Hotel />,
        link: "/market-dues-items",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Market due rates",
        Icon: <Hotel />,
        link: "/market-dues-rates",
        visible: GetPermissions([CAN_ADMIN]),
      },
    ],
  },

  {
    title: sPayments,
    Icon: <CreditScore />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Currencies",
        Icon: <CurrencyPound />,
        link: "/currencies",
      },
      {
        title: "Bank Codes",
        Icon: <AccountBalance />,
        link: "/bank-codes",
      },
    ],
  },

  {
    title: "Hotel Settings",
    visible: GetPermissions([CAN_ADMIN]),
    Icon: <Hotel />,
    link: "/",
    children: [
      {
        title: "Hotel Classifications",
        Icon: <Hotel />,
        link: "/hotel-classifications",
      },
      {
        title: "Hotel Facility Rating",
        Icon: <Stars />,
        link: "/hotel-facility-rating",
      },
    ],
  },

  {
    title: "Advertisement Settings",
    Icon: <AddBusiness />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Advertisement Classifications",
        Icon: <AddBusiness />,
        link: "/advertisement-classification",
      },

      {
        title: "Tool Types",
        Icon: <AddBusiness />,
        link: "/tool-types",
      },

      {
        title: "Tool Sizes",
        Icon: <AddBusiness />,
        link: "/tool-sizes",
      },
      {
        title: "Outdoor Advertising Rates",
        Icon: <AddBusiness />,
        link: "/outdoor-rates",
      },
      {
        title: "Material Used",
        Icon: <AddBusiness />,
        link: "/material-used",
      },
    ],
  },

  {
    title: "Transportation",
    Icon: <EmojiTransportation />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Vehicle Classifications",
        Icon: <LocalActivityOutlined />,
        link: "/vehicle-classifications",
      },
      {
        title: "Vehicle Purpose",
        Icon: <MinorCrash />,
        link: "/vehicle-purpose",
      },
      {
        title: "Vehicle Category",
        Icon: <Subway />,
        link: "/vehicle-category",
      },
      {
        title: "Vehicle Tax Rates",
        Icon: <Commute />,
        link: "/vehicle-tax-rates",
      },
      {
        title: "Vehicle Assessments",
        Icon: <LocalGasStationRounded />,
        link: "/vehicle-assessments",
      },
      {
        title: "Parks",
        Icon: <LocalParking />,
        link: "/parks",
      },
      {
        title: "Vehicle Operation Categories",
        Icon: <Approval />,
        link: "/vehicle-operation-categories",
      },
      {
        title: "Stages",
        Icon: <Approval />,
        link: "/stages",
      },
      {
        title: "Routes",
        Icon: <Route />,
        link: "/routes",
      },
    ],
  },
  {
    title: "Usage Settings",
    Icon: <Inventory2 />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Usage",
        Icon: <Inventory />,
        link: "/usage",
      },
      {
        title: "Sub Usage",
        Icon: <Article />,
        link: "/sub-usage",
      },
      {
        title: "Accommondation Categories",
        Icon: <Article />,
        link: "/accommondation-categories",
      },
    ],
  },

  {
    title: sRevenueSources,
    Icon: <Paid />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Chart Of Accounts",
        Icon: <Inventory />,
        link: "/revenue-categories",
      },
      {
        title: "Revenue Source Items",
        Icon: <Inventory />,
        link: "/revenue-source-items",
      },
      {
        title: "Configure Demand Date",
        Icon: <Inventory />,
        link: "/demand-dates",
      },
    ],
  },

  {
    title: "Revenue Budget",
    Icon: <Paid />,
    visible: GetPermissions([CAN_ADMIN]) || GetPermissionRoles(APP_AUTHORITY),
    link: "/",
    children: [
      {
        title: "Revenue Budget",
        Icon: <Inventory />,
        link: "/revenue-budget",
        visible:
          GetPermissions([CAN_ADMIN]) || GetPermissionRoles(APP_AUTHORITY),
      },
      {
        title: "Revenue Ration Sharing",
        Icon: <Inventory />,
        link: "/revenue-share-ratio",
        visible:
          GetPermissions([CAN_ADMIN]) || GetPermissionRoles(APP_AUTHORITY),
      },
      {
        title: "Revenue Source",
        Icon: <Inventory />,
        link: "/revenue-source",
        visible:
          GetPermissions([CAN_ADMIN])
      },
      // {
      //   title: "Ecomic Growth",
      //   Icon: <Inventory />,
      //   link: "/economic-growth",
      //   visible:
      //     GetPermissions([CAN_ADMIN]) || GetPermissionRoles(APP_AUTHORITY),
      // },
      // {
      //   title: "Forecast Config",
      //   Icon: <Inventory />,
      //   link: "/forecast-config",
      //   visible:
      //     GetPermissions([CAN_ADMIN]) || GetPermissionRoles(APP_AUTHORITY),
      // },
    ],
  },

  {
    title: "Departments",
    Icon: <Settings />,
    link: "/departments",
    visible: GetPermissions([CAN_ADMIN]),
  },

  {
    title: "Tenderer Revenue Source Item ",
    Icon: <Paid />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/tenderer-revenue-source-items",
  },
  {
    title: "Land Use",
    Icon: <Landscape />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/land-use",
  },

  {
    title: "Valuation Periods",
    Icon: <FaClock />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/valuation-periods",
  },

  {
    title: sDevelopmentControl,
    Icon: <FaWarehouse />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Permit questions",
        Icon: <Inventory />,
        link: "/permit-questions",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Site Inspection questions",
        Icon: <Inventory />,
        link: "/site-inspection-questions",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Site Inspection Answers",
        Icon: <Inventory />,
        link: "/site-inspection-answers",
        visible: GetPermissions([CAN_ADMIN]),
      },

      {
        title: "Permit Fees",
        Icon: <Business />,
        link: "/permit-fees/",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Other Permit Fees",
        Icon: <Business />,
        link: "/other-permit-fees/",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Professionals",
        Icon: <Person />,
        link: "/professionals/",
        visible: GetPermissions([CAN_ADMIN]),
      },
    ],
  },
  {
    title: "Workflows",
    Icon: <CardTravel />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Create Workflow",
        Icon: <CardTravel />,
        link: "/scrutiny-workflows",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Development Control Role",
        Icon: <FaSlackHash />,
        link: "/development-control-roles",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Scrutiny Workflow Configuration",
        Icon: <FaBorderNone />,
        link: "/scrutiny-workflow-config",
        visible: GetPermissions([CAN_ADMIN]),
      },
      {
        title: "Escalation Periods",
        Icon: <FaClock />,
        link: "/escalation-periods",
      },
    ],
  },
  // Logos and Signatures
  {
    title: "Logos and Signatures",
    Icon: <Architecture />,
    visible: GetPermissions([CAN_ADMIN]),
    children: [
      {
        title: "Upload Logo and Signature",
        Icon: <Description />,
        link: "/logo-signature/upload",
      },

      {
        title: "View Register",
        Icon: <AssignmentTurnedIn />,
        link: "/logo-signature/register",
      },
    ],
  },
  // Local Goverment Profile
  {
    title: "Local Goverment Profile",
    Icon: <AdminPanelSettings />,
    visible: GetPermissions([CAN_ADMIN]),
    children: [
      {
        title: "Profile Types",
        Icon: <Description />,
        link: "/profile-types",
        visible: GetPermissions([CAN_ADMIN]),
      },

      {
        title: "Profile Post",
        Icon: <AssignmentTurnedIn />,
        link: "/local-government-profile",
        visible: GetPermissions([CAN_ADMIN]),
      },
    ],
  },
  {
    title: "Entity Settings",
    Icon: <Business />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/",
    children: [
      {
        title: "Entity Types",
        Icon: <Work />,
        link: "/entity-types",
      },
      {
        title: "Entity Sub Types",
        Icon: <BusinessCenter />,
        link: "/entity-sub-types",
      },
    ],
  },
  {
    title: "All Configurables",
    Icon: <Settings />,
    visible: GetPermissions([CAN_ADMIN]),
    link: "/all-configurables",
  },
];

export const reportsOptions = () => [
  {
    title: "Adhoc Reports",
    link: "/reports/adhoc",
  },
  {
    title: "Reports Summary",
    link: "/reports/summary",
    visible: !IsPropertyTaxAdmin(),
  },
  {
    title: "Reports Summary (FYs)",
    link: "/reports/summary/fys",
    visible: GetPermissions([CAN_ADMIN]) && !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  {
    title: "Budget Performance",
    link: "/reports/budget-performance",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  // {
  //   title: "Revenue Forecasting",
  //   link: "/reports/forecast",
  //   visible: !IsPropertyTaxAdmin(),
  // },
  {
    title: "Revenue Sharing Ratio",
    link: "/reports/revenue-sharing-ratio",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },

  {
    title: sDevelopmentControl,
    link: "/reports/development-control",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  // {
  //   title: "Park User Fees PRN Sharing",
  //   link: "/reports/park-prn-sharing",
  //   visible: GetPermissions([CAN_ADMIN, CAN_MUNICIPALITY, CAN_DIVISION]),
  // },
  // {
  //   title: "Park User Fees Payment Sharing",
  //   link: "/reports/park-payment-sharing",
  //   visible: GetPermissions([CAN_ADMIN, CAN_MUNICIPALITY, CAN_DIVISION]),
  // },
  {
    title: "Park User Payment",
    link: "/reports/park-user-payment",
    visible:
      GetPermissions([CAN_ADMIN, CAN_MUNICIPALITY, CAN_DIVISION]) &&
      !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  {
    title: "Park User Pending PRNs",
    link: "/reports/park-user-pending-payment",
    visible:
      GetPermissions([CAN_ADMIN, CAN_MUNICIPALITY, CAN_DIVISION]) &&
      !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  {
    title: "IFMS (Tax Heads)",
    link: "/reports/ifms",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
  {
    title: "IFMS (Local Governments)",
    link: "/reports/ifms-lgs",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },

  {
    title: "Demand Notice",
    link: "/reports/demand-notices",
    visible: !IsPropertyTaxAdmin() && !HasUserType([MARKET_ADMIN])
  },
];

export const account = [
  { title: "Profile", Icon: <Person />, link: "/users/profile", visible: true },
  {
    title: "Change Password",
    Icon: <Password />,
    link: "/change-password",
    visible: true,
  },
  { title: "Logout", Icon: <Logout />, link: "/logout", visible: true },
];

export const profileTabs = [
  {
    title: "User Dashboard",
    Icon: <UserOutlined />,
    component: <UserProfile />,
  },
  // {
  //   title: "Account Settings",
  //   Icon: <SettingOutlined />,
  // },
  {
    title: "Assessments",
    Icon: <CalculatorOutlined />,
    component: <InvoicesOwner />,
  },
  {
    title: sPayments,
    Icon: <CreditCardOutlined />,
    component: <PaymentsPublicUser />,
  },
  {
    title: "Business Branch Details",
    Icon: <ShopFilled />,
    component: <BusinessBranchRegister />,
  },
  {
    title: "TIN Registration",
    Icon: <FileDoneOutlined />,
    component: <SetInstantTinForm />,
  },
];

export const tradeLicenseTabs = [
  {
    title: "Details",
    component: <TradingLicenseDetails />,
  },
  {
    title: "Assessments",
    component: <AssessmentsRegister />,
  },
];

// GENERAL LEDGER

export const ledgerTabs = [
  {
    title: "Sub Ledger",
    Icon: <CalculatorOutlined />,
    component: <GeneralLedgerRegister />,
  },
  {
    title: "Arrears Ledger",
    Icon: <CreditCardOutlined />,
    component: <ArrearsLedgerRegister />,
  },
  {
    title: "Tax Head Ledger",
    Icon: <CreditCardOutlined />,
    component: <RevenueLedgerRegister />,
  },
  {
    title: sPayments,
    Icon: <CreditCardOutlined />,
    component: <PaymentsPublicUser />,
  },
  {
    title: "Documents",
    Icon: <FileDoneOutlined />,
  },
];
