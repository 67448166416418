import { useState } from "react";

const useForm = (keys) => {
  const [form, setForm] = useState(keys || {});

  const updateFormValue = (key, value) => {
    // Update state
    // Assign new value to the appropriate form field
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateFormValues = (obj) => {
    // Update state
    // Assign new value to the appropriate form field
    setForm({
      ...form,
      ...obj,
    });
  };

  const onChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    updateFormValue(name, value);
  };

  // validation
  const validateForm = (validators, setError) => {
    let errors = null;

    let isValid = true;
    validators.forEach(({ notValid, field, required, visible = true }) => {
      if (notValid) {
        errors = {
          ...(errors || {}),
          [field]: notValid,
        };
        isValid = false;
      }

      if (required && !form[field] && visible) {
        errors = {
          ...(errors || {}),
          [field]: "This field is required",
        };
        isValid = false;
      }
    });

    setError(errors);
    return isValid;
  };

  return {
    form,
    setForm,
    updateFormValue,
    updateFormValues,
    onChange,
    validateForm,
  };
};

export default useForm;
