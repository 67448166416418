import useGroundRent, {
  useGroundRentFormSelect,
} from "./ground-rent/useGroundRent";
import useLocalHotelTax, {
  useLocalHotelTaxFormSelect,
} from "./local-hotel-tax/useLocalHotelTax";
import useLocalServiceTax, {
  useLocalServiceTaxFormSelect,
} from "./local-service-tax/useLocalServiceTax";
import useMarketRents, {
  useMarketRentsFormSelect,
} from "./market-rent/useMarketRents";
import useOntr, { useOntrFormSelect } from "./ontr/useOntr";
import useOperationalPermit, {
  useOperationalPermitFormSelect,
} from "./operational-permit/useOperationalPermit";
import useOutdoorAdverting, {
  useOutdoorAdvertingFormSelect,
} from "./outdoor-advertising/useOutdoorAdvertising";
import useProperty, {
  usePropertyFormSelect,
} from "./property-rates/property-tax/usePropertyTax";
import useRentandRates, {
  useRentAndRatesFormSelect,
} from "./rent-and-rates/useRentandRates";
import useTenderer, { useTendererFormSelect } from "./tenderer/useTenderer";
import useTradingLicense, {
  useTradingLicenseFormSelect,
} from "./trading-license/useTradingLicense";
import usePropertyValuation, {
  usePropertyValuationFormSelect,
} from "./property-rates/property-valuation/usePropertyValuation";
import useParkUserFees, {
  useParkUserFeesFormSelect,
} from "./park-user-fees/useParkUserFees";
import useMarketDues, { useMarketDuesFormSelect } from "./market-dues/useMarketDues";

export const revenueSourceHooks = {
  trade_license: useTradingLicense,
  lht: useLocalHotelTax,
  lst: useLocalServiceTax,
  market_rent: useMarketRents,
  ground_rent: useGroundRent,
  outdoor_advertising: useOutdoorAdverting,
  operational_permit: useOperationalPermit,
  property: useProperty,
  rent_and_rates: useRentandRates,
  ontr: useOntr,
  park_user_fees: useParkUserFees,
  tenderers: useTenderer,
  property_valution: usePropertyValuation,
  market_dues: useMarketDues,
};

export const revenueSourceSelectHooks = {
  trade_license: useTradingLicenseFormSelect,
  lht: useLocalHotelTaxFormSelect,
  lst: useLocalServiceTaxFormSelect,
  market_rent: useMarketRentsFormSelect,
  ground_rent: useGroundRentFormSelect,
  outdoor_advertising: useOutdoorAdvertingFormSelect,
  operational_permit: useOperationalPermitFormSelect,
  rent_and_rates: useRentAndRatesFormSelect,
  property: usePropertyFormSelect,
  ontr: useOntrFormSelect,
  park_user_fees: useParkUserFeesFormSelect,
  tenderers: useTendererFormSelect,
  property_valution: usePropertyValuationFormSelect,
  market_dues: useMarketDuesFormSelect,
};
