import React, { useState } from "react";
import { saveAs } from "file-saver";
import {
  BANK_CODE_API,
  GENERATE_PAF_API,
  INVOICE_API,
  PAYMENTS_API,
  PAY_MODE_API,
  PRN_API,
  PRN_CANCEL_API,
  PRN_RESEND_API,
} from "../../constants/api";
import { AVAILABLE, CANCELLED, PRN_PAID } from "../../constants/webapp";
import useAxios from "../axos/useAxios";
import axiosInstance from "../../utils/axios";
import {
  DownloadLink,
  DownloadAnchorButton,
} from "../../components/common/Button";
import { downloadThroughAnchorLink } from "../../utils/helperUtil";
import { Button } from "antd";

function usePaf() {
  const [modes, setModes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [prns, setPrns] = useState([]);
  const [sendPrn, setResendPrn] = useState();
  const [cancelledPrn, setCancelPrn] = useState();
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(false);
  const { get, post } = useAxios();
  const [open, setOpen] = useState(false);
  const [ID, setID] = useState();
  const cancelApi = PRN_CANCEL_API;

  const resendPrn = (params) => {
    get({
      api: PRN_RESEND_API,
      loader: setLoading,
      successHandler: (data) => setResendPrn(data),
      errorHandler: setError,
      params,
    });
  };

  const getModes = () => {
    get({
      api: PAY_MODE_API,
      successHandler: (data) => setModes(data?.results),
    });
  };
  const getBanks = () => {
    get({
      api: BANK_CODE_API,
      successHandler: (data) => setBanks(data?.results),
    });
  };

  const getInvoice = (id) => {
    get({
      api: `${INVOICE_API}${id}`,
      loader: setLoading,
      successHandler: setInvoice,
    });
  };

  const getPrns = (params) => {
    get({
      api: PRN_API,
      successHandler: (data) => setPrns(data?.results),
      params,
    });
  };

  const createPaf = (params) => {
    const { getParams } = params;
    post({
      api: GENERATE_PAF_API,
      loader: setSubmitting,
      successHandler: (data) => {
        setSuccess(`Successfully generated PRN - ${data.ref}`);
        getPrns({
          ...(getParams && getParams),
          ...(!getParams && {
            invoice: params.invoice,
            for_tendered: params.for_tendered === true ? true : false,
            for_advanced: params.for_advanced === true ? true : false,
            for_penalties: params.for_penalties === true ? true : false,
            time: new Date().toLocaleString(),
          }),
        });
      },
      errorHandler: setError,
      params,
    });
  };

  const getPrintPaf = (id) => {
    axiosInstance.get(`${PRN_API}${id}/print-paf/`).then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, "paf.pdf");
    });
  };

  const statusOptions = (objStatus) => {
    let options;

    switch (objStatus) {
      case AVAILABLE:
        options = {
          subtitle: "Not Paid",
          title: "Submit",
        };
        break;

      case PRN_PAID:
        options = {
          subtitle: "Paid",
        };
        break;

      default:
        options = {};
    }

    return <>{options?.subtitle}</>;
  };

  const pafTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        id: item?.id,
        ref: item?.ref,
        amount: item?.amount.toLocaleString(),
        paid: item?.paid,
        status:
          item?.status === AVAILABLE ? (
            <>
              Not Paid{" "}
              <div style={{ display: "inline-block" }}>
                <DownloadAnchorButton
                  url={`${PRN_API}print-paf/?id=${item?.id}`}
                  fileName="paf"
                  title="[[PAF]"
                />
              </div>
              {/* Resend PRN */}
              <Button
                type="primary"
                size="small"
                className="font13"
                style={{ marginLeft: 10 }}
                onClick={() => resendPrn({ id: item?.id })}
              >
                Resend PRN
              </Button>
              {/* <Button
                type="primary"
                size="small"
                className="font13"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setOpen(true);
                  setID(item.id);
                }}
              >
                Cancel PRN
              </Button> */}
            </>
          ) : item?.status === PRN_PAID ? (
            <>
              Credited{" "}
              <DownloadAnchorButton
                url={`${PAYMENTS_API}print-receipt-prn/?prn_id=${item?.id}`}
                fileName="Receipt"
                title="[Receipt]"
              />
            </>
          ) : item?.status === CANCELLED ? (
            <>Cancelled</>
          ) : null,
      };
      return row;
    }),
  ];

  const pafColumns = [
    { id: "ref", header: "PRN", filter: {} },
    { id: "amount", header: "Amount", filter: {} },
    { id: "status", header: "Status" },
  ];

  return {
    modes,
    banks,
    error,
    submitting,
    loading,
    invoice,
    prns,
    pafColumns,
    success,
    sendPrn,
    pafTableData,
    getModes,
    getBanks,
    getInvoice,
    setError,
    getPrns,
    createPaf,
    getPrintPaf,
    statusOptions,
    open,
    setOpen,
    ID,
    cancelApi,
  };
}

export default usePaf;
