import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { FaFilePdf, FaImage } from "react-icons/fa";
import { AiFillFileWord } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import { DownloadButton, SubmitButton } from "../components/common/Button";
import DocumentViewer from "../components/common/viewers/DocumentViewers";
import { BASE_DOC_API } from "../constants/api";
import {
  DOC_TYPE_REPORT,
  FILE_TYPE_DOC,
  FILE_TYPE_DOCX,
  FILE_TYPE_JPEG,
  FILE_TYPE_JPG,
  FILE_TYPE_PDF,
  FILE_TYPE_PNG,
  OBJECTION_NOT_ON_VALUE,
  OBJECTION_ON_VALUE,
  STATUS_ISSUED_OUT,
  STATUS_READY,
  STATUS_RENEWED,
} from "../constants/webapp";
import { isSuperUser } from "./authUtils";
import axiosInstance from "./axios";
import { openModal } from "./modalUtils";
import SelectInput from "../components/common/SelectInput";
import FileInput from "../components/common/FileInput";
import DateInput from "../components/common/DateInput";
import { Visibility } from "../components/common/Visibility";
import PhoneNumberInput from "../components/common/PhoneNumberInput";
import ReturnPeriodsInvoices from "../components/pages/payments/ReturnPeriodsInvoices";
import moment from "moment";
import dayjs from "dayjs";
import { dateFormat } from "../components/pages/dashboard/ReportFilter";
import StopOversForm from "../components/pages/park-user-fees/StopOversForm";
import DetailsCard from "../components/common/cards/DetailsCard";

const objectionTypes = [
  {
    id: OBJECTION_NOT_ON_VALUE,
    name: "Objection Not on Value",
  },
  {
    id: OBJECTION_ON_VALUE,
    name: "Objection On Value",
  },
];

export const getSearchKeyValue = (key) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params[key];
};

export const createFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

export const addManyToFormdata = (formData, manyToMany) => {
  manyToMany.forEach(({ data, key }) => {
    data.forEach((d) => {
      formData.append(key, d);
    });
  });
  return formData;
};

export const parseJwt = (tkn) => {
  if (!tkn) {
    return null;
  }
  const base64Url = tkn.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const returnObject = (show, object) => {
  return show ? object : [];
};

export const objectWithUnsetKeys = (object, keys) => {
  const temp = { ...object };
  keys.forEach((item) => {
    delete temp[item];
  });
  return temp;
};

export const generateAttachments = (files, document_type, object) => {
  return (files || []).map((file) => ({
    document_type,
    file,
    ...object,
  }));
};

export const prepareAttachmentsPost = (attachments, field, object, noFile) => {
  const prefix = field || "attachments";
  const extras = {};
  const atts = (attachments || []).filter((item) => noFile || item?.file);
  const inflated_data = [];
  atts.forEach((item) => {
    if (item?.file) {
      item?.file.forEach((it) => {
        inflated_data.push({ ...item, file: it });
      });
    } else {
      inflated_data.push(item);
    }
  });

  inflated_data
    .map((item) => ({ ...item, ...object }))
    .forEach((item, idx) => {
      Object.keys(item).forEach((key) => {
        extras[`${prefix}[${idx}]${key}`] = item[key];
      });
    });
  return extras;
};

export const prepareObjectFormdataPost = (object, field) => {
  const extras = {};
  Object.keys(object).forEach((key) => {
    extras[`${field}.${key}`] = object[key];
  });
  return extras;
};

export const downloadCertificate = async (downloadUrl, fileName) => {
  await axiosInstance
    .get(downloadUrl, {
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const previewCertificate = async (downloadUrl, setPreviewLoading) => {
  if (setPreviewLoading) {
    setPreviewLoading(true);
  }
  await axiosInstance
    .get(downloadUrl, {
      responseType: "blob",
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      window.open(href, "_blank");
      if (setPreviewLoading) {
        setPreviewLoading(false);
      }
    });
};

export const flattenErrors = (nestedErrors) => {
  const flatErrors = {};

  const flatten = (object) => {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && !Array.isArray(value)) {
        flatten(value, key);
      } else {
        flatErrors[key] = value;
      }
    }
  };

  flatten(nestedErrors);
  return flatErrors;
};

export const downloadThroughAnchorLink = (
  downloadUrl,
  fileName,
  setLoading,
  params,
) => {
  if (setLoading) setLoading(true);
  axiosInstance
    .get(downloadUrl, {
      ...(params && { params: params }),
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      if (setLoading) setLoading(false);
    });
};

export const useDownloadThroughAnchorLink = () => {
  const [loading, setLoading] = useState(false);

  const downloadThroughAnchorLink = (downloadUrl, fileName) => {
    setLoading(true);
    axiosInstance
      .get(downloadUrl, {
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setLoading(false);
      });
  };

  return { loading, downloadThroughAnchorLink };
};

export const renderAttachments = (attachments, filename) => {
  return (attachments || []).map((item) => (
    <DownloadButton
      onClick={() =>
        downloadThroughAnchorLink(
          `${BASE_DOC_API}${item.file}`,
          item.name || filename
        )
      }
    />
  ));
};

export const isPdf = (file) => {
  return [FILE_TYPE_PDF].includes(String(file).split(".").pop());
};

export const isMSWord = (file) => {
  return [FILE_TYPE_DOCX, FILE_TYPE_DOC].includes(
    String(file).split(".").pop()
  );
};

export const isImage = (file) => {
  return [FILE_TYPE_PNG, FILE_TYPE_JPEG, FILE_TYPE_JPG].includes(
    String(file).split(".").pop()
  );
};

export const renderSingleFile = (item, filename) => {
  const openDocument = () =>
    openModal({
      body: <DocumentViewer docs={[{ uri: `${BASE_DOC_API}${item?.file}` }]} />,
      style: { width: "100%" },
      hideActions: true,
    });
  return (
    <>
      <Button
        type="primary"
        className="btn-secondary"
        icon={
          isPdf(item?.file) ? (
            <FaFilePdf />
          ) : isMSWord(item?.file) ? (
            <AiFillFileWord />
          ) : (
            <FaImage />
          )
        }
        size={20}
        onClick={openDocument}
      >
        View
      </Button>

      <span style={{ margin: 5 }}>
        <Button
          type="primary"
          className="btn-primary"
          icon={<DownloadOutlined />}
          size={20}
          onClick={() =>
            downloadThroughAnchorLink(
              `${BASE_DOC_API}${item.file}`,
              item.name || filename
            )
          }
        />
      </span>
    </>
  );
};

export const generateDocumentAuthor = (user) => ({
  author_first_name: user?.first_name,
  author_last_name: user?.last_name,
  author_email: user?.email,
  author_phone_number: user?.profile?.phone_number,
});

export const displayDocuments = (docs) => {
  const st = {};
  Object.keys(docs || {})
    .filter((key) => docs[key])
    .map((key) => docs[key])
    .forEach((item) => {
      item.forEach((doc, idx) => {
        const caption = idx > 0 ? idx + 1 : "";
        st[`${doc.caption}_${caption}`] = renderSingleFile(doc, doc.caption);
      });
    });

  return st;
};

export const displayImages = (images) => {
  return Object.keys(images || {})
    .filter((key) => images[key])
    .map((key) => images[key])
    .map((item) => {
      return (
        <img
          alt="photo"
          src={`${BASE_DOC_API}${item?.[0]?.file}`}
          height={80}
          width={80}
          style={{ margin: 10 }}
        />
      );
    });
};

export const canPrintDocument = (user, docStatus) => {
  return (
    ([STATUS_READY, STATUS_RENEWED].includes(docStatus) && isSuperUser(user)) ||
    ([STATUS_ISSUED_OUT, STATUS_RENEWED].includes(docStatus) &&
      isSuperUser(user))
  );
};

export const canEdit = (user_data, id, resource) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (userData.id === user_data) {
    return (
      <Link
        style={{ padding: 5, paddingBottom: 0 }}
        class=" mr-2"
        to={`${resource}/update/${id}`}
      >
        <FaEdit />
      </Link>
    );
  } else {
    return null;
  }
};

export const currFilename = (file) => {
  return file?.map((item) => item.name).toString();
};

export const renderReview = (viewData, amendments, amendableFields) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          {Object.keys(viewData).map((key) => {
            const fieldConfig = (amendableFields || [])
              .filter((item) => item.id === key)
              .reduce((acc, curr) => curr, null);

            const amendConfig = amendments
              .filter((item) => item.field_name === fieldConfig?.field)
              .reduce((acc, curr) => curr, null);
            return (
              <tr>
                <th style={{ textTransform: "capitalize" }}>
                  {key !== "Stop Over Divisions"
                    ? String(key).replaceAll("_", " ")
                    : null}
                </th>
                <td>
                  {amendConfig
                    ? amendConfig.old_value
                    : key !== "Stop Over Divisions"
                      ? viewData[key]
                      : null}
                </td>
                <td>
                  {amendConfig
                    ? fieldConfig?.type === "select" ||
                      fieldConfig?.type === "stop_overs"
                      ? amendConfig.new_value_name
                      : amendConfig.new_value
                    : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const renderPRReview = (
  viewData,
  valuation,
  amendableFields,
  options,
  form,
  onChange,
  updateFormValue,
  hideAmendableFields
) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          {Object.keys(viewData)?.map((key) => {
            const fieldConfig = (amendableFields || [])
              ?.filter((item) => (item.field || item.id) === key)
              ?.reduce((acc, curr) => curr, null);
            return (
              <tr>
                <th style={{ textTransform: "capitalize" }}>
                  {String(key).replaceAll("_", " ")}
                </th>
                <td>{viewData[key]}</td>
                <Visibility show={hideAmendableFields === "Object"}>
                  <td>
                    {amendableFields?.map((field) => field.id).includes(key)
                      ? renderField(
                          fieldConfig,
                          form[fieldConfig.field || fieldConfig.id],
                          onChange,
                          updateFormValue,
                          fieldConfig.type === "select"
                            ? (options || {})[fieldConfig.options]
                            : null
                        )
                      : null}
                  </td>
                </Visibility>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const renderARForm = (
  viewData,
  amendableFields,
  form,
  onChange,
  updateFormValue,
  updateFormValues,
  submitting,
  options
) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          {Object.keys(viewData).map((key) => {
            const fieldConfig = amendableFields
              ?.filter((item) => item.id === key)
              .reduce((acc, curr) => curr, {});
            return (
              <tr>
                <th style={{ textTransform: "capitalize" }}>
                  {String(key).replaceAll("_", " ")}
                </th>
                <td>{viewData[key]}</td>
                <td>
                  {amendableFields?.map((field) => field.id).includes(key)
                    ? renderField(
                        fieldConfig,
                        form[fieldConfig.field || fieldConfig.id],
                        onChange,
                        updateFormValue,
                        fieldConfig.type === "select"
                          ? (options || {})[fieldConfig.options]
                          : null,
                        updateFormValues,
                        form
                      )
                    : null}
                </td>
              </tr>
            );
          })}
          <tr>
            <th>
              Reason / Comment (<span className="text-danger">*</span>)
            </th>
            <td></td>
            <td>
              <input
                className="form-control"
                type="text"
                name="reason"
                onChange={onChange}
                value={form?.reason}
                // error={error}
                required
              />
            </td>
          </tr>
          <tr>
            <th>
              Supporting document (<span className="text-danger">*</span>)
            </th>
            <td></td>
            <td>
              <FileInput
                onChange={(value) => updateFormValue("doc", value)}
                name="attachment"
                documentType={DOC_TYPE_REPORT}
                multiple
                required
              />
            </td>
          </tr>
          <tr>
            <th></th>
            <td></td>
            <td>
              <SubmitButton submitting={submitting} text="Submit" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const detailsCardView = (item) => {
  let new_item = {};
  Object.keys(item).map((key) => {
    if (!key.includes("-name"))
      new_item[key] =
        item[`${key}-name`] != null ? item[`${key}-name`] : item[key];
  });
  return new_item;
};

export const beautify = (message) => {
  return (message || "")
    .replaceAll("_", " ")
    .replaceAll("/", "")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const cleanNumber = (value) => {
  const num = Number((value || "0").toString().replace(/,/g, "")); // Ensure value is a string, remove commas, and convert to a number
  return isNaN(num) ? 0 : num; // Prevent NaN issues
};

export const numberFormat = (value) => Number(value || 0).toLocaleString();

export const renderPVForm = (
  viewData,
  amendableFields,
  form,
  onChange,
  updateFormValue,
  updateFormValues,
  submitting,
  options
) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          {Object.keys(viewData).map((key) => {
            const fieldConfig = amendableFields
              ?.filter((item) => item.id === key)
              .reduce((acc, curr) => curr, {});

            return (
              <tr>
                <th style={{ textTransform: "capitalize" }}>
                  {String(key).replaceAll("_", " ")}
                </th>
                <td>{viewData[key]}</td>
                <td>
                  {amendableFields?.map((field) => field.id).includes(key)
                    ? renderPVField(
                        fieldConfig,
                        form[fieldConfig.field || fieldConfig.id],
                        onChange,
                        updateFormValue,
                        fieldConfig.type === "select"
                          ? (options || {})[fieldConfig.options]
                          : null,
                        updateFormValues
                      )
                    : null}
                </td>
              </tr>
            );
          })}
          <tr>
            <th></th>
            <td></td>
            <td>
              <SubmitButton submitting={submitting} text="Submit" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const renderPRForm = (
  viewData,
  amendableFields,
  form,
  onChange,
  updateFormValue,
  submitting,
  options
) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="panel panel-primary">
          <table
            id="w1"
            className="table table-striped table-bordered detail-view"
          >
            <tbody>
              {Object.keys(viewData)?.map((key) => {
                return (
                  <tr className="row">
                    <th style={{ textTransform: "capitalize" }}>
                      {String(key).replaceAll("_", " ")}
                    </th>
                    <td>{viewData[key]}</td>
                    <td>Amendable Fields</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const renderBusinessTransferForm = (
  viewData,
  form,
  newOwner,
  registration,
  onChange,
  updateFormValue,
  handleChange,
  selectedValue,
  handleReasonChange,
  submitting,
  options
) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="panel panel-primary">
            <table
              id="w1"
              className="table table-striped table-bordered detail-view"
            >
              <tbody>
                {Object.keys(viewData).map((key) => {
                  return (
                    <tr className="row">
                      <th style={{ textTransform: "capitalize" }}>
                        {String(key).replaceAll("_", " ")}
                      </th>
                      <td>{viewData[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="panel panel-primary">
            <table className="table table-striped table-bordered detail-view">
              <tbody>
                {newOwner?.results?.map((item, key) => {
                  return (
                    <>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          First Name
                        </th>
                        <td>{item?.first_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Email</th>
                        <td>{item.email}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Phone Number
                        </th>
                        <td>{item.mobile_no}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>CIN</th>
                        <td>{item.cin}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Full Name
                        </th>
                        <td>{item?.first_name + " " + item?.last_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Municipality
                        </th>
                        <td>{item?.municipality_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Division
                        </th>
                        <td>{item?.division_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Parish</th>
                        <td>{item?.parish_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Street</th>
                        <td>{item?.street_name}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="panel panel-primary">
            <table className="table table-striped table-bordered detail-view">
              <tbody>
                <>
                  <tr className="row">
                    <th style={{ textTransform: "capitalize" }}>#</th>
                    <th style={{ textTransform: "capitalize" }}>Period</th>
                    <th style={{ textTransform: "capitalize" }}>Amount</th>
                  </tr>

                  {registration?.outstanding_invoices?.map((item) => (
                    <>
                      <tr className="row">
                        <td>{item?.id}</td>
                        <td>{item?.period_name}</td>
                        <td>{item?.amount}</td>
                      </tr>
                    </>
                  ))}
                </>
              </tbody>
            </table>
          </div>
          <div>
            <h4>
              <strong>Commitment on outstanding balance</strong>
            </h4>
            <p>
              Total Outstanding Balance: UGX{" "}
              {parseInt(registration?.outstanding_balance)?.toLocaleString()}
            </p>
            <div class="form-group">
              <label>Who is liable to the business outstanding balance?</label>
              <div class="form-group">
                <input
                  type="radio"
                  value="current"
                  checked={selectedValue === "current"}
                  onChange={handleChange}
                />
                <label>Current Owner</label>
                <p>
                  <span>
                    (if selected, the transfer of the business will only be
                    complete when the balance is cleared)
                  </span>
                </p>
              </div>
              <div class="form-group">
                <input
                  type="radio"
                  value="new"
                  checked={selectedValue === "new"}
                  onChange={handleChange}
                />
                <label>New Owner</label>
              </div>
            </div>

            <h4>
              <strong>Other</strong>
            </h4>
            <div class="form-group">
              <label for="reason">
                Reason <span class="text-danger">*</span>
              </label>
              <input type="hidden" name="transfer_to" value="" />
              <textarea
                class="form-control"
                name="reason"
                id="reason"
                required
                rows="5"
                onChange={handleReasonChange}
              ></textarea>
            </div>
            <div class="form-group">
              <label for="remarks">Remarks (optional)</label>
              <input
                class="form-control"
                name="remarks"
                id="remarks"
                onChange={(event) =>
                  updateFormValue("remarks", event.target.value)
                }
              />
            </div>

            <div class="form-group">
              <label for="attachment">
                Attachment (less than 10MBs) <span class="text-danger">*</span>
              </label>
              <FileInput
                label="Upload Attachment (if any)"
                onChange={(value) => updateFormValue("attachment", value)}
                name="attachment"
                documentType={DOC_TYPE_REPORT}
              />
            </div>

            <div class="form-group">
              <DateInput
                label="Date of Effect"
                id="date_of_effect"
                name="date_of_effect"
                onChange={(value) => updateFormValue("date_of_effect", value)}
                value={form.date_of_effect}
                required
                format="YYYY-MM-DD"
              />
            </div>
            <div>
              <SubmitButton submitting={submitting} text="Submit" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const renderPaymentsForm = (
  viewData,
  form,
  newOwner,
  registration,
  onChange,
  updateFormValue,
  handleChange,
  selectedValue,
  handleReasonChange,
  submitting,
  options
) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="panel panel-primary">
            <table
              id="w1"
              className="table table-striped table-bordered detail-view"
            >
              <tbody>
                {Object.keys(viewData).map((key) => {
                  return (
                    <tr className="row">
                      <th style={{ textTransform: "capitalize" }}>
                        {String(key).replaceAll("_", " ")}
                      </th>
                      <td>{viewData[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-12">
          <ReturnPeriodsInvoices
            outstanding_invoices={viewData?.outstanding_invoices}
          />
        </div>
      </div>
    </>
  );
};

export const renderBusinessDeactivationForm = (
  viewData,
  form,
  newOwner,
  registration,
  onChange,
  updateFormValue,
  handleChange,
  selectedValue,
  handleReasonChange,
  submitting,
  options
) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="panel panel-primary">
            <table
              id="w1"
              className="table table-striped table-bordered detail-view"
            >
              <tbody>
                {Object.keys(viewData).map((key) => {
                  return (
                    <tr className="row">
                      <th style={{ textTransform: "capitalize" }}>
                        {String(key).replaceAll("_", " ")}
                      </th>
                      <td>{viewData[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* </div>
        <div className="col-sm-6"> */}
          {/* <div className="panel panel-primary">
            <table className="table table-striped table-bordered detail-view">
              <tbody>
                {newOwner?.results?.map((item, key) => {
                  return (
                    <>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          First Name
                        </th>
                        <td>{item.first_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Email</th>
                        <td>{item.email}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Phone Number
                        </th>
                        <td>{item.mobile_no}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>CIN</th>
                        <td>{item.cin}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Full Name
                        </th>
                        <td>{item.first_name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Municipality
                        </th>
                        <td>{item?.municipality_details?.name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>
                          Division
                        </th>
                        <td>{item?.division_details?.name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Parish</th>
                        <td>{item?.parish_details?.name}</td>
                      </tr>
                      <tr className="row">
                        <th style={{ textTransform: "capitalize" }}>Street</th>
                        <td>{item?.street_details?.name}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div> */}
          <div>
            <div class="form-group">
              <label for="reason">
                Reason <span class="text-danger">*</span>
              </label>
              <input type="hidden" name="transfer_to" value="" />
              <textarea
                class="form-control"
                name="deactivate_reason"
                id="deactivate_reason"
                required
                rows="5"
                onChange={handleReasonChange}
              ></textarea>
            </div>

            <FileInput
              label="Upload Attachment (if any)"
              onChange={(value) => updateFormValue("attachment", value)}
              name="attachment"
              required
              documentType={DOC_TYPE_REPORT}
            />

            <div>
              <SubmitButton submitting={submitting} text="Submit" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const renderField = (
  fieldConfig,
  value,
  onChange,
  updateFormValue,
  options,
  updateFormValues,
  form
) => {
  const updateRatableValue = (e) => {
    if (e.target.value <= form?.old_ratable_value) {
      updateFormValue("ratable_value", e.target.value);
    } else {
      alert("New value must not be greater than the original value.");
    }
  };

  if (fieldConfig?.type === "select") {
    return (
      <SelectInput
        options={(options || []).map((item) => ({
          value:
            fieldConfig.field === "park" || fieldConfig.field === "stage"
              ? item?.name
              : item.id,

          label: fieldConfig.field === "route" ? item?.route_name : item.name,
          ...returnObject(fieldConfig.field === "route", {
            route_code: item.route_code,
            route_category: item.route_category,
            start_division: item?.start_route_details?.name,
            end_division: item?.end_route_details?.name,
          }),
          ...returnObject(fieldConfig.field === "r_lst_threshold", {
            amount: item.amount,
          }),
          ...returnObject(fieldConfig.field === "park", {
            park_id: item.id,
          }),
        }))}
        onChange={({ value, label }, obj) => {
          if (updateFormValues) {
            // console.log(value);
            updateFormValues({
              [fieldConfig.field]:
                fieldConfig.field === "tender_validity"
                  ? parseInt(value)
                  : value,
              [`${fieldConfig.field}_name`]: label,
              ...returnObject(fieldConfig.field === "r_lst_threshold", {
                r_lst_threshold_amount: obj.amount,
              }),
              ...returnObject(fieldConfig.field === "stop_overs", {
                stop_overs: obj,
              }),
              ...returnObject(fieldConfig.field === "route", {
                route_category: obj?.route_category,
                route_code: obj?.route_code,
                start_division: obj?.start_division,
                end_division: obj?.end_division,
              }),
            });
          } else {
            updateFormValue(fieldConfig.field, value);
          }
        }}
        value={
          fieldConfig.field === "tender_validity" ||
          fieldConfig.field === "r_lst_threshold"
            ? parseInt(value)
            : fieldConfig.field === "route"
              ? form["route_name"]
              : value
        }
        labelInValue
        {...fieldConfig.props}
      />
    );
  } else if (fieldConfig?.type === "tel") {
    if (value?.charAt(0) === "0") {
      value = value?.replace(value?.charAt(0), "256");
    }

    return (
      <PhoneNumberInput
        type="text"
        country="ug"
        name={fieldConfig.field}
        onChange={(value) => updateFormValue(fieldConfig.field, value)}
        value={value}
      />
    );
  } else if (fieldConfig?.type === "stop_overs") {
    const stopOversViewData = (item) => ({
      Municipality: item.municipality_name,
      Division: item.division_name,
    });

    const addStopOvers = () => {
      openModal({
        body: <StopOversForm formId="holder-form" />,
        title: "Stop Over",
        formId: "holder-form",
        type: "form",
        okText: "Add",
      }).then((data) => {
        updateFormValue("stop_overs", [...(form?.stop_overs || []), data]);
      });
    };

    const onRemoveItem = (key, index) => {
      updateFormValue(
        key,
        (form[key] || []).filter((_, idx) => idx !== index)
      );
    };

    return (
      <>
        {(form?.stop_overs || []).map((item, idx) => (
          <DetailsCard
            key={idx}
            viewData={stopOversViewData(item)}
            onDelete={() => onRemoveItem("stop_overs", idx)}
          />
        ))}

        <Button
          type="primary"
          className="btn-secondary"
          size={20}
          onClick={addStopOvers}
        >
          Add Stop Over
        </Button>
      </>
    );
  } else
    return (
      <input
        className="form-control"
        type={fieldConfig?.type}
        name={fieldConfig.field}
        onChange={
          fieldConfig.field === "ratable_value" ? updateRatableValue : onChange
        }
        value={value}
        {...fieldConfig.props}
      />
    );
};

export const renderPVField = (
  fieldConfig,
  value,
  onChange,
  updateFormValue,
  options,
  updateFormValues
) => {
  if (fieldConfig?.type === "select") {
    return (
      <SelectInput
        options={(options || []).map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={({ value, label }) => {
          if (updateFormValues) {
            updateFormValues({
              [fieldConfig.field]:
                fieldConfig.field === "doors" || fieldConfig.field === "windows"
                  ? label
                  : value,
              [`${fieldConfig.field}_name`]: label,
            });
          } else {
            updateFormValue(
              fieldConfig.field,
              fieldConfig.field === "doors" || fieldConfig.field === "windows"
                ? label
                : value
            );
          }
        }}
        value={value}
        labelInValue
      />
    );
  } else if (fieldConfig?.type === "tel") {
    return (
      <PhoneNumberInput
        type="text"
        country="ug"
        name={fieldConfig.field}
        onChange={(value) => updateFormValue(fieldConfig.field, value)}
        value={value}
      />
    );
  } else
    return (
      <input
        className="form-control"
        type={fieldConfig?.type}
        name={fieldConfig.field}
        onChange={onChange}
        value={value}
        {...fieldConfig.props}
      />
    );
};

export const renderObjectionForm = (
  viewData,
  amendableFields,
  form,
  onChange,
  updateFormValue,
  submitting,
  options
) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          <tr>
            <th>
              Objection Type (<span className="text-danger">*</span>)
            </th>
            <td></td>
            <td>
              <SelectInput
                label="Objection Type"
                name="objection_type"
                onChange={(value) => updateFormValue("objection_type", value)}
                options={objectionTypes.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={form.objection_type}
              />
            </td>
          </tr>
          {Object.keys(viewData).map((key) => {
            const fieldConfig = amendableFields
              .filter((item) => item.id === key)
              .reduce((acc, curr) => curr, {});
            return (
              <tr>
                <th style={{ textTransform: "capitalize" }}>
                  {String(key).replaceAll("_", " ")}
                </th>
                <td>{viewData[key]}</td>
                <td>
                  {amendableFields.map((field) => field.id).includes(key)
                    ? renderField(
                        fieldConfig,
                        form[fieldConfig.field || fieldConfig.id],
                        onChange,
                        updateFormValue,
                        fieldConfig.type === "select"
                          ? (options || {})[fieldConfig.options]
                          : null
                      )
                    : null}
                </td>
              </tr>
            );
          })}

          <tr>
            <th></th>
            <td></td>
            <td>
              <SubmitButton submitting={submitting} text="Submit" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const renderValuationObjectionForm = (
  viewData,
  amendableFields,
  form,
  onChange,
  updateFormValue,
  submitting,
  options
) => {
  return (
    <div className="panel panel-primary">
      <table id="w1" className="table table-striped table-bordered detail-view">
        <tbody>
          {Object.keys(viewData).map((key) => {
            return (
              <tr className="row">
                <th style={{ textTransform: "capitalize" }}>
                  {String(key).replaceAll("_", " ")}
                </th>
                <td>{viewData[key]}</td>
              </tr>
            );
          })}

          <tr>
            <th></th>
            <td></td>
            <td>
              <SubmitButton submitting={submitting} text="Submit" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const handleOwnerData = (payload) => {
  return {
    owner_name:
      payload.is_individual === 0
        ? payload?.legal_name
        : `${payload.surname} ${payload.first_name}`,
    owner_data: {
      pwd_expiry_status: "active",
      username:
        payload.email === undefined ||
        payload.email === "" ||
        payload.email === null
          ? payload.mobile_number
          : payload.email,
      email: payload.email,
      cin: payload.cin || payload.nin || payload.brn,
      mobile_no: payload.mobile_number,
      ...returnObject(payload.is_individual === 0, {
        legal_name: payload?.legal_name,
      }),
      ...returnObject(payload.is_individual === 1, {
        first_name: payload.first_name,
        last_name: payload.surname,
      }),
      profile: {
        municipality: payload.municipality,
        division: payload.division,
        id_type: payload?.idtype,
        ...returnObject(payload.is_individual === 0, {
          full_name: payload.legal_name,
        }),
        ...returnObject(payload.is_individual === 1, {
          first_name: payload.first_name,
          ...returnObject(payload.surname, {
            last_name: payload.surname,
          }),
        }),
        is_individual: payload.is_individual,
        natitionality: payload.nationality,
      },
    },
  };
};

export const auditActivityTableData = (data) => [
  ...data?.map((item) => {
    const row = {
      timestamp: moment(item?.timestamp).format("ll"),
      actor: item?.actor?.profile?.full_name,
      action_comment: item?.data?.action_comment,
      action: item?.action,
    };
    return row;
  }),
];

export const auditActivityTableColumns = () => [
  { id: "action", header: "Action", filter: {} },
  { id: "actor", header: "Actor", filter: {} },
  { id: "action_comment", header: "Comment", filter: {} },
  { id: "timestamp", header: "Date", filter: {} },
];

export function setPickerDate(date) {
  if (date) return dayjs(date, dateFormat);
}

export const stringify = (value) => value?.replaceAll("_", " ");
