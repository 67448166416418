import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";

const TextArearInput = (props) => {
  const {
    label,
    name,
    value,
    row,
    defaultValue,
    onChange,
    error,
    errorNameKey,
    ...rest
  } = props;

  let errorKey = name;

  if (errorNameKey) {
    errorKey = errorNameKey;
  }

  return (
    <div className="form-group field-user-cin">
      {error && error[errorKey] && <Error message={error[errorKey]} />}
      <label>
        {label} {rest.required && <span className="text-danger">*</span>}
      </label>
      <textarea
        style={{ fontFamily: "inherit" }}
        className="form-control"
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        rows={row}
        // eslint-disable-next-line
        {...rest}
      />
    </div>
  );
};

TextArearInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default TextArearInput;
