import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaBook, FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { ButtonLink } from "../styled/StyledButton";
import Loader from "./Loader";
import TableFilter from "./Filter";
import { Visibility } from "./Visibility";
import { cleanNumber, downloadThroughAnchorLink } from "../../utils/helperUtil";
import { DownloadOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { DropdownPopup } from "./dropdowns/DropdownPopup";
import { Button } from "antd";

const Table = (props) => {
  const {
    tableData: incomingData,
    tableColumns,
    tableTitle,
    rowData,
    loading,
    count,
    onFilterChange,
    filters,
    excelDownloadApi,
    csvDownloadApi,
    pdfDownloadApi,
    success,
    error,
    rowTableData,
  } = props;

  const [tableData, setSortedData] = useState(incomingData || []);
  const [sortOrder, setSortOrder] = useState("asc");

  const onChange = (key, value) => {
    onFilterChange({ ...filters, [key]: value });
  };

  const updateFormValues = (obj) => {
    onFilterChange({ ...filters, ...obj });
  };

  function createData(title, Icon, api) {
    return {
      title: title,
      Icon: Icon,
      click: () => {
        downloadThroughAnchorLink(
          api,
          tableTitle,
          ...Array(4).fill(undefined),
          filters
        );
      },
    };
  }

  function getDownloadOptions() {
    let data = [];
    if (excelDownloadApi) {
      data.push(createData("Excel", <FaFileExcel />, excelDownloadApi));
    }
    if (csvDownloadApi) {
      data.push(createData("CSV", <FaFileCsv />, csvDownloadApi));
    }
    if (pdfDownloadApi) {
      data.push(createData("PDF", <FaFilePdf />, pdfDownloadApi));
    }
    return data;
  }

  useEffect(() => {
    if (incomingData) {
      setSortedData(incomingData);
    }
  }, [incomingData]);

  const handleSort = (key) => {
    const data = [...rowData]?.sort((a, b) => {
      return sortOrder === "asc"
        ? cleanNumber(a[key]) - cleanNumber(b[key])
        : cleanNumber(b[key]) - cleanNumber(a[key]);
    });
    setSortedData(rowTableData([...data]));
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sort order
  };

  return (
    <>
      {/* <AlertMessage
        message={location.state?.message || message}
        type={error ? "error" : "success"}
        timeout={15000}
        margin="15px 0"
      /> */}

      <div className="trade-license-index">
        <div
          id="w5"
          className="grid-view hide-resize"
          data-krajee-grid="kvGridInit_89c8ba5d"
        >
          <div className="panel panel-primary">
            <div className="panel-heading flex flexSpaceCenter">
              <div>
                <div className="pull-right">
                  <div className="summary">
                    {tableData.length > 0 && (
                      <div className="font14">
                        Showing{" "}
                        <b>
                          {((filters?.page || 1) - 1) * 20 + 1}-
                          {((filters?.page || 1) - 1) * 20 + tableData.length}
                        </b>{" "}
                        of <b>{count}</b> items.
                      </div>
                    )}
                  </div>
                </div>
                <h3 className="panel-title">
                  <FaBook style={{ verticalAlign: "middle" }} />
                  <span
                    className="link-title"
                    style={{
                      verticalAlign: "middle",
                      marginLeft: 10,
                      fontSize: 17,
                      textTransform: "capitalize",
                    }}
                  >
                    {tableTitle}
                  </span>
                </h3>
                <div className="clearfix" />
              </div>

              <Visibility
                show={excelDownloadApi || csvDownloadApi || pdfDownloadApi}
              >
                <DropdownPopup menu={getDownloadOptions()}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "green",
                    }}
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>
                </DropdownPopup>
              </Visibility>
            </div>

            <div
              id="w5-container"
              className="table-responsive kv-grid-container"
            >
              <table className="kv-grid-table table table-bordered table-striped kv-table-wrap">
                <thead>
                  <tr
                    style={{
                      background: "#f3f3f4",
                    }}
                  >
                    <th
                      style={{
                        color: "black",
                      }}
                    >
                      #
                    </th>
                    {tableColumns?.map((row, i) => (
                      <th
                        key={i}
                        style={{ whiteSpace: "nowrap", color: "black" }}
                        className="sortable"
                      >
                        <ButtonLink>{row.header}</ButtonLink>
                        {row?.filter?.sort === true ? (
                          <SortAscendingOutlined
                            onClick={() => handleSort(row.id)}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                  <tr
                    style={{
                      background: "#f3f3f4",
                    }}
                    id="w5-filters"
                    className="filters skip-export"
                  >
                    <td />
                    {tableColumns?.map((row, i) => {
                      return row.filter ? (
                        <td
                          key={i}
                          style={{
                            whiteSpace: "nowrap",
                            border: "1px solid #ddd",
                          }}
                        >
                          <TableFilter
                            name={row.filter?.key ? row.filter?.key : row.id}
                            onChange={onChange}
                            updateFormValues={updateFormValues}
                            filters={filters}
                            options={row?.options}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...row.filter}
                          />
                        </td>
                      ) : (
                        <td />
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan={tableColumns?.length}
                        className="text-center"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: "white",
                        }}
                      >
                        <Loader />
                      </td>
                    </tr>
                  ) : tableData.length === 0 ? (
                    <tr>
                      <td
                        colSpan={tableColumns?.length + 1}
                        className="text-center font14"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    tableData?.map((row, i) => (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          {((filters?.page || 1) - 1) * 10 + i + 1}
                        </td>
                        {tableColumns?.map((item) => (
                          <td
                            style={{
                              verticalAlign: "middle",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row[item.id]}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="kv-panel-after" />
            <div className="panel-footer">
              <div className="kv-panel-pager">
                {loading ||
                  (tableData?.length > 0 && count && (
                    <ReactPaginate
                      breakLabel="..."
                      previousLabel={<span>«</span>}
                      onPageChange={({ selected }) =>
                        onChange("page", selected + 1)
                      }
                      pageRangeDisplayed={3}
                      pageCount={Math.ceil(count / 10)}
                      marginPagesDisplayed={3}
                      nextLabel={<span>»</span>}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination justify-content-end"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      forcePage={(filters?.page || 1) - 1}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                    />
                  ))}
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Table.propTypes = {
  tableData: PropTypes.arrayOf({}),
  tableColumns: PropTypes.arrayOf({}),
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
  excelDownloadApi: PropTypes.string,
  csvDownloadApi: PropTypes.string,
  pdfDownloadApi: PropTypes.string,
  onFilterChange: PropTypes.func,
  filters: PropTypes.shape({ page: PropTypes.number }),
};

export const StatTable = ({ title, headers, data, totals, containerStyle }) => {
  return (
    <div className="br-pagebody mg-t-5 bs-card" style={{ ...containerStyle }}>
      <h4 className="desc">{title}</h4>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr style={{ background: "#069", color: "#fff" }}>
              <th>#</th>
              {headers?.map((it) => (
                <th>{it.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td style={{ verticalAlign: "middle" }}>{idx + 1}</td>
                {headers?.map((it, i) => (
                  <td
                    style={{
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                    }}
                    key={i}
                  >
                    {item[it.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ fontSize: "100%" }} className="info">
              <th>TOTAL</th>
              {headers?.map((it) => (
                <th> {totals[it.id]}</th>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

StatTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  totals: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.string),
  containerStyle: PropTypes.shape({ margin: PropTypes.number }),
};

export default Table;
