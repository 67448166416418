import React, { cloneElement, createContext, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { parseJwt } from "../utils/helperUtil";
import useUsers from "../hooks/users/useUsers";
import axiosInstance from "../utils/axios";
import { useAppContext } from "./AppContext";
import useAutoLogout from "../hooks/common/useAutoLogout";
import {LOGOUT_USER_DURATION} from "../constants/webapp";

const api = process.env.REACT_APP_BASE_API;

const authContext = createContext({});

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const AuthProvider = ({ children, ...rest }) => {
  const [singleUser, setSingleUser] = useState();

  // Get single User
  const getSingleUser = (id) => {
    axiosInstance
      .get(`accounts/users/${id}`)
      .then((res) => {
        // handle success
        setSingleUser(res.data);
      })
      .catch((err) => {
        // handle error
      });
  };

  // const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});

  const { updateContext } = useAppContext();
  useEffect(() => {
    updateContext("userData", userData);
  }, [userData]);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setUser(parseJwt(localToken).user);
    }
  }, []);

  // useEffect(() => {
  //   const localUserData = localStorage.getItem("userData");
  //   if (localUserData) {
  //     setUserData(JSON.parse(localUserData));
  //   }
  // }, []);

  useEffect(() => {
    // not empty
    if (singleUser && !isEmpty(singleUser)) {
      localStorage.setItem("userData", JSON.stringify(singleUser));
      setUserData(singleUser);
    }
  }, [singleUser]);

  const login = (username, password) => {
    setSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ username, password });

    try {
      axios
        .post(`${api}token/`, body, config)
        .then((res) => {
          setToken(localStorage.setItem("token", res.data.access));
          localStorage.setItem('refresh-token',res.data.refresh )
          setSubmitting(false);
          setLoggedIn(true);
          setUser(parseJwt(localStorage.getItem("token")).user);
          // get single user details
          getSingleUser(parseJwt(localStorage.getItem("token")).user.id);
        })
        .catch((error) => {
          // handle error
          var errorMessage = "An error occured while logging in";
          setSubmitting(false);
          if (error.code === "ERR_NETWORK") {
            errorMessage = "Check your network connection";
          } else if (error.response.status === 401) {
            errorMessage = "Your username or password is incorrect";
          } else if (
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            errorMessage = "Server Error. Try again later";
          }
          setError(errorMessage);
        });
    } catch (err) {
      setSubmitting(false);
      setError("Error authenticating");
    }
  };

  const refresh = () => {
    try {
      axios.post(`${api}refresh/`,
          { refresh: localStorage.getItem('refresh-token')},
          { headers: { "Content-Type": "application/json"}
          }).then((res) => {
            setToken(localStorage.setItem("token", res.data.access));
            setUser(parseJwt(localStorage.getItem("token")).user);
            getSingleUser(parseJwt(localStorage.getItem("token")).user.id);
            window.location.replace('/users/profile')
      }).catch(error => {
        var errorMessage = "An error occured while logging in";
        setSubmitting(false);
        if (error.code === "ERR_NETWORK") {
          errorMessage = "Check your network connection";
        } else if (error.response.status === 401) {
          errorMessage = "Your username or password is incorrect";
        } else if (
            error.response.status === 500 ||
            error.response.status === 503
        ) {
          errorMessage = "Server Error. Try again later";
        }
        setError(errorMessage);
      })
    } catch (error) {
      setError("Error authenticating");
    }
  }

  const signup = ({ name, email, password, password2 }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name, email, password, password2 });

    try {
      axios.post(`${api}accounts/signup`, body, config).then(() => {
        localStorage.removeItem("token");
        setLoggedIn(false);
        login(email, password);
      });
    } catch (err) {
      //
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("userData");
    window.location = "/user/login";
    setLoggedIn(false);
  };

  useAutoLogout(logout,  LOGOUT_USER_DURATION);

  const renderBody = (ele, properties) => cloneElement(ele, properties);

  return (
    <authContext.Provider
      // eslint-disable-next-line
      value={{
        token,
        login,
        loggedIn,
        logout,
        signup,
        submitting,
        error,
        user,
        userData,
        refresh
      }}
    >
      {renderBody(children, rest)}
    </authContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuth = () => React.useContext(authContext);

const LogoutRoute = () => useAuth().logout();

export { AuthProvider, useAuth, LogoutRoute };
