import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import { ARREARS_KEY } from "../../../constants/webapp";

export const SelectAntTable = (props) => {
  const {
    tableData,
    tableColumns,
    tableTitle,
    onSelect,
    footer,
    selectAll = false,
    disableAll = false,
    totalsData = {},
  } = props;
  const defaultTitle = () => <h3>{tableTitle}</h3>;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onSelect(selectedRows.filter((row) => row.key !== ARREARS_KEY));
    },

    getCheckboxProps: (record) => ({
      disabled: disableAll || record.key === ARREARS_KEY,
      defaultChecked: record.key === ARREARS_KEY,
    }),
    ...(selectAll && {
      selectedRowKeys: tableData
        .filter((row) => row.key !== ARREARS_KEY)
        .map((row) => row.key),
    }),
  };

  const tableProps = {
    bordered: true,
    // size: "small",
    // tableLayout: "fixed",
    title: tableTitle ? defaultTitle : undefined,
    rowSelection: {
      type: "checkbox",
      ...rowSelection,
    },
  };
  const getTableData = () => {
    if (totalsData && Object.keys(totalsData).length > 0) {
      return [...tableData, totalsData];
    }
    return tableData;
  };

  return (
    <Table
      {...tableProps}
      columns={tableColumns}
      dataSource={getTableData()}
      pagination={false}
      footer={() => footer}
    />
  );
};

SelectAntTable.propTypes = {
  tableData: PropTypes.arrayOf({}),
  tableColumns: PropTypes.arrayOf({}),
  tableTitle: PropTypes.string,
};
