import { Add, Delete } from "@mui/icons-material";
import { Button } from "antd";
import { useEffect } from "react";
import useGenericGet from "../../../hooks/common/useGenericGet";
import {
  API_ALL_MARKET_DUE_CATEGORIES,
  MARKET_DUES_ITEMS_API,
  MARKET_DUES_RATES_API,
} from "../../../constants/api";
import SelectInput from "../../common/SelectInput";
import FormInput from "../../common/FormInput";

const MarketDuesAssessment = (props) => {
  const { form, updateFormValue, isDailyPaymentInterval, validator} = props;
  const { data: categories, loadData: getCategories } = useGenericGet();
  const rowsData = form?.breakdown || [];

  const rowsInput = {
    category: "",
  };

  const dailyPaymentIntervalRowsInput = {
    category: "",
    quantity: "",
    item: "",
    rate: "",
    total: ""
  }

  useEffect(() => {
    getCategories({
      api: API_ALL_MARKET_DUE_CATEGORIES,
      params: { municipality: form.municipality },
    });
  }, []);

  const addTableRows = () => {
    if(isDailyPaymentInterval) {
      updateFormValue("breakdown", [...rowsData, dailyPaymentIntervalRowsInput]);
    } else {
      updateFormValue("breakdown", [...rowsData, rowsInput]);
    }
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    updateFormValue("breakdown", rows);
  };

  const handleChange = (index, name, value) => {
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    updateFormValue("breakdown", rowsInput);
  };

  useEffect(() => {
    if (rowsData.length == 0) {
      addTableRows();
    }
  }, [rowsData]);

  useEffect(() => {
    if(isDailyPaymentInterval) {
      updateFormValue("breakdown", [dailyPaymentIntervalRowsInput]);
    } else {
      updateFormValue("breakdown", [rowsInput]);
    }
  }, [isDailyPaymentInterval]);

  return rowsData.map((data, index) => {
    const { category, item, rate, quantity, total } = data;
    return (
      <TableRowItem
        categories={categories}
        handleChange={handleChange}
        addTableRows={addTableRows}
        category={category}
        rate={rate}
        quantity={quantity}
        item={item}
        deleteTableRows={deleteTableRows}
        index={index}
        municipality={form?.municipality}
        total={total}
        isDailyPaymentInterval={isDailyPaymentInterval}
        validator={validator}
      />
    );
  });
};

const TableRowItem = ({
  index,
  categories,
  handleChange,
  addTableRows,
  category,
  quantity,
  rate,
  deleteTableRows,
  item,
  municipality,
  total,
  validator,
  isDailyPaymentInterval
}) => {
  const { data: items, loadData: getItems } = useGenericGet();
  const { data: rates, loadData: getRates } = useGenericGet();

  validator.current.purgeFields();

  useEffect(() => {
    if (category)
      getItems({
        api: MARKET_DUES_ITEMS_API,
        params: { category, municipality },
      });
  }, [category]);

  useEffect(() => {
    if (item) {
      getRates({ api: MARKET_DUES_RATES_API, params: { item, municipality } });
    }
  }, [item]);

  useEffect(() => {
    if (rates?.results?.length) {
      handleChange(index, "rate", rates?.results?.[0]?.amount_per_unit);
    }
  }, [rates]);

  useEffect(() => {
    if (quantity) {
      handleChange(index, "total", quantity * rate);
    }
  }, [quantity]);

  return (
    <div
      className="row flexNullCenter"
      style={{ marginTop: 5, marginBottom: 5 }}
    >
      <div className="col-sm-2">
        <SelectInput
          label="Market due Category"
          name="category"
          placeholder="Select category"
          value={category}
          onChange={(value) => {
            handleChange(index, "category", value);
          }}
          options={(categories || []).map((sup) => ({
            label: sup.name,
            value: sup.id,
          }))}
          required
        />
        {validator.current.message(
          "Market due Category",
          category,
          "required|integer"
        )}
      </div>
      {
        isDailyPaymentInterval &&
        <>
          <div className="col-sm-3">
            <SelectInput
              label="Market due Item"
              name="item"
              value={isDailyPaymentInterval ? item : ""}
              onChange={({ value, label }) => {
                handleChange(index, "item", value);
              }}
              options={(items?.results || []).map((sup) => ({
                placeholder: "Select Item...",
                label: sup.name,
                value: sup.id,
              }))}
              required
              labelInValue
            />
            {validator.current.message(
              "Market due Item",
              item,
              "required|integer"
            )}
          </div>
          <div className="col-sm-2">
            <FormInput
              type="number"
              value={rate}
              readOnly
              label="Rate"
              name="rate"
              placeholder="Rate"
              required
            />
          </div>
          <div className="col-sm-2">
            <FormInput
              label="Quantity"
              name="quantity"
              onChange={(evnt) =>
                handleChange(index, "quantity", Number(evnt.target.value))
              }
              placeholder="Quantity"
              value={quantity}
              type="number"
              required
            />
            {validator.current.message(
              "Quantity",
              quantity,
              "required|integer"
            )}
          </div>
          <div className="col-sm-2">
            <FormInput
              type="number"
              label="Amount"
              name="total"
              value={total}
              onChange={(evnt) =>
                handleChange(index, "total", Number(evnt.target.value))
              }
              placeholder="Item Total"
              readOnly={isDailyPaymentInterval}
              required
            />
          </div>
        </>
      }

      <div className="flex gap10" style={{ paddingRight: 20 }}>
        {index > 0 && (
          <Button icon={<Delete />} onClick={() => deleteTableRows(index)} />
        )}
        <Button icon={<Add />} onClick={addTableRows} />
      </div>
    </div>
  );
};

export default MarketDuesAssessment;
