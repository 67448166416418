import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaDownload, FaEye, FaRedo, FaTrashAlt, FaUndo } from "react-icons/fa";
import { Modal, Tooltip } from "antd";
import { SpinnerDotted } from "spinners-react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { configColor } from "../styled/Theme";
import {
  downloadThroughAnchorLink,
  previewCertificate,
} from "../../utils/helperUtil";

export const ModalLoader = ({ show }) => {
  return (
    <Modal
      open={show}
      footer={null}
      closable={false}
      bodyStyle={{ width: "100vh", textAlign: "center" }}
      className="bs-modal-style"
    >
      <SpinnerDotted
        size={100}
        thickness={240}
        color="#337ab7"
        style={{ marginTop: 50 }}
      />
    </Modal>
  );
};

ModalLoader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export const SubmitButton = ({
  submitting,
  isEdit,
  text,
  submitText,
  disabled,
  ...rest
}) => {
  return (
    <>
      {/* <ModalLoader show={submitting} /> */}
      <button
        type="submit"
        className="btn btn-success"
        disabled={submitting || disabled}
        // eslint-disable-next-line
        {...rest}
      >
        {submitting
          ? isEdit
            ? "Updating"
            : submitText || "Submitting"
          : isEdit
            ? "Update"
            : text || "Submit"}
      </button>
    </>
  );
};

SubmitButton.propTypes = {
  submitText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export const GenerateButton = ({ submitting, isEdit }) => {
  return (
    <button type="submit" className="btn btn-success" disabled={submitting}>
      {submitting
        ? isEdit
          ? "Updating"
          : "Submitting"
        : isEdit
          ? "Update"
          : "Generate Bill"}
    </button>
  );
};

GenerateButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export const DeleteButton = ({ onClick }) => {
  return (
    <button
      style={{
        padding: 5,
        paddingBottom: 0,
        background: "none",
        border: "none",
        display: "inline-block",
      }}
      type="button"
      onClick={onClick}
    >
      <FaTrashAlt color="#a94442" />
    </button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const DeactivateButton = ({ onClick }) => {
  return (
    <Tooltip title="Deactivate">
      <Link
        style={{
          padding: 5,
          paddingBottom: 0,
          background: "none",
          border: "none",
          display: "inline-block",
        }}
        type="button"
        onClick={onClick}
      >
        <FaUndo color="#fc030b" />
      </Link>
    </Tooltip>
  );
};

DeactivateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const ActivateButton = ({ onClick, label }) => {
  return (
    <Tooltip title={label || "Activate"}>
      <Link
        style={{
          padding: 5,
          paddingBottom: 0,
          background: "none",
          border: "none",
          display: "inline-block",
        }}
        type="button"
        onClick={onClick}
      >
        <FaRedo color="#0a9147" />
      </Link>
    </Tooltip>
  );
};

ActivateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export const DownloadButton = ({ onClick, title }) => {
  return (
    <button
      style={{
        padding: 5,
        paddingBottom: 0,
        background: "none",
        border: "none",
        display: "inline-block",
      }}
      type="button"
      onClick={onClick}
    >
      <FaDownload color="#777" /> {title || "download"}
    </button>
  );
};

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export const DownloadAnchorButton = ({ url, fileName, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
      <DownloadLink
        loading={loading}
        onClick={() => {
          downloadThroughAnchorLink(url, fileName, setLoading);
        }}
        {...props}
      />
      <PreviewIcon
        loading={previewLoading}
        onClick={() => previewCertificate(url, setPreviewLoading)}
      />
    </div>
  );
};

export const DownloadLink = ({
  onClick,
  title,
  color,
  icon,
  paf = false,
  loading = false
}) => {
  color = color || "#337ab7";
  const Icon = icon || FaDownload;
  if (loading)
    return (
      <span style={{ display: "inline-flex" }}>
        <CircularProgress size={15} sx={{ mr: 1.5 }} />
        <span className="font13" style={{ color: configColor }}>
          Downloading...
        </span>
      </span>
    );
  return (
    <button
      style={{
        padding: 0,
        background: "none",
        border: "none",
        display: "inline-block",
        color: color,
        cursor: "pointer",
      }}
      type="button"
      onClick={onClick}
    >
      {!paf && <Icon color={color} />} {title}
    </button>
  );
};

DownloadLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const PreviewIcon = ({ onClick, loading = false }) => {
  if (loading)
    return (
      <span style={{ display: "inline-flex" }}>
        <CircularProgress size={15} sx={{ mr: 1.5 }} />
        {/*<span className="font13" style={{ color: configColor }}>*/}
        {/*    Loading preview...*/}
        {/*</span>*/}
      </span>
    );

  return (
    <Tooltip title="Preview">
      <FaEye onClick={onClick} cursor="pointer" />
    </Tooltip>
  );
};

export const Button = (props) => {
  /* eslint-disable react/jsx-props-no-spreading */
  const { title, loaderTitle, loading, type, theme, ...rest } = props;

  return (
    <button
      className={`btn btn-${theme}`}
      /* eslint-disable react/button-has-type */
      type={type}
      disabled={loading}
      {...rest}
    >
      {loading ? loaderTitle : title}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  loaderTitle: PropTypes.string,
  theme: PropTypes.string.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
};
