import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import {
  MARKET_DUES_API,
  API_GENERATE_BILL
} from "../../../constants/api";
import Loader from "../../common/Loader";
import MarketDuesAssessment from "./MarketDuesAssessment";
import { 
  MARKET_DUE_DAILY
} from "../../../constants/webapp";
import SimpleReactValidator from "simple-react-validator";
import axiosInstance from "../../../utils/axios";

const MarketDuesBillAssessment = ({ formId, period }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('tax_id');
  const navigate = useNavigate();

  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <div
          style={{
            fontSize: 14,
            color: "red",
            marginTop: -10,
            marginBottom: 5,
          }}
        >
          {message}
        </div>
      ),
    })
  );

  validator.current.purgeFields();

  const { form, updateFormValue, onChange, updateFormValues } = useForm();
  const { loadData: getMarketRent, data: marketDue, loading,  } = useGenericGet();

  useEffect(() => {
    getMarketRent({ api: MARKET_DUES_API, id: id });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }

    if(marketDue?.payment_interval === MARKET_DUE_DAILY) {
      axiosInstance
        .patch(`${MARKET_DUES_API}${marketDue.id}/`, {
          ...form,
        })
        .then(() => {
          return axiosInstance.post(`${API_GENERATE_BILL}`, {
            tax: "market_dues",
            period: period,
            tax_id: marketDue.id,
            amount: form?.amount
          });
        })
        .then(() => {
          navigate(0);
        })
        .catch((err) => {});
    } else {
      axiosInstance
        .post(`${API_GENERATE_BILL}`, {
          tax: "market_dues",
          period: period,
          tax_id: marketDue.id,
          amount: marketDue.amount
        })
        .then(() => {
          navigate(0);
        })
        .catch((err) => {});
    };
  };

  useEffect(() => {
    if (marketDue) {
      updateFormValues({
        amount: marketDue?.amount,
        breakdown: marketDue?.breakdown_details.map(({
          category, 
          quantity,
          item,
          rate,
          total
        }) => ({ 
          category, 
          quantity,
          item,
          rate,
          total
        })),
      });
    }
  }, [marketDue]);

  useEffect(() => {
    if (form.breakdown && marketDue?.payment_interval === MARKET_DUE_DAILY) {
      updateFormValue(
        "amount",
        (form.breakdown || []).reduce((acc, obj) => acc + obj.total, 0)
      );
    }
  }, [form.breakdown]);

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Market Dues</div>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-lg-12">
              {
                marketDue?.payment_interval === MARKET_DUE_DAILY &&
                <MarketDuesAssessment
                  form={form}
                  updateFormValue={updateFormValue}
                  isDailyPaymentInterval={
                    marketDue?.payment_interval === MARKET_DUE_DAILY ? true : false
                  }
                  validator={validator}
                />
              }
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FormInput
                type="number"
                value={Number(form?.amount)}
                onChange={onChange}
                label="Total Amount"
                name="amount"
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

MarketDuesBillAssessment.propTypes = {};

export default MarketDuesBillAssessment;
