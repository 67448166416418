import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import Error from "./Error";
import useForm from "../../hooks/common/useForm";
import { useAuth } from "../../context/authContext";
import { generateDocumentAuthor } from "../../utils/helperUtil";
import { DOC_TYPE_OTHER } from "../../constants/webapp";
import FormInput from "./FormInput";

const FileInput = (props) => {
  const {
    onChange,
    label,
    name,
    error,
    documentType,
    isImage,
    customUser,
    showCaption,
    required,
    errorNameKey,
    ...rest
  } = props;
  const { user } = useAuth();
  const { form: doc, updateFormValue, onChange: onDocChange } = useForm();
  const documentAuthor = generateDocumentAuthor(user?.id ? user : customUser);

  const onFileChange = (event) => {
    updateFormValue(
      "file",
      event.target.files?.length ? Array.from(event.target.files) : ""
    );
  };

  useEffect(() => {
    if (onChange) {
      onChange({
        ...doc,
        ...documentAuthor,
        document_type: documentType,
        caption: name,
      });
    }
  }, [doc]);

  let errorKey = name;

  if (errorNameKey) {
    errorKey = errorNameKey;
  }

  return (
    <div className="row">
      <div
        className={showCaption ? "col-sm-6" : "col-sm-12"}
        style={{ padding: 0 }}
      >
        <div className="form-group field-user-cin">
          {error && error[errorKey] && <Error message={error[errorKey]} />}
          <label>
            {label}{" "}
            {(required || doc?.file) && <span className="text-danger">*</span>}
          </label>
          {!isImage && (
            <span className="greyColor font12 regular">
              {" "}
              (pdf, docx, png, svg, jpg, jpeg) - Maximum upload size 5Mb
            </span>
          )}
          <input
            type="file"
            onChange={onFileChange}
            accept={`${
              isImage
                ? ".png,.svg,.jpg,.jpeg"
                : ".pdf,.png,.svg,.jpg,.jpeg,.docx"
            }`}
            style={{
              padding: 10,
              borderRadius: 6,
              border: "1px solid #ccc",
              width: "100%",
            }}
            required={required}
            // eslint-disable-next-line
            {...rest}
          />
        </div>
      </div>

      {showCaption ? (
        <div className="col-sm-6">
          <FormInput
            name="caption"
            value={doc?.description}
            label="File Caption"
            onChange={onDocChange}
            required={doc?.file}
            // eslint-disable-next-line
            {...rest}
          />
        </div>
      ) : null}
    </div>
  );
};

FileInput.defaultProps = {
  documentType: DOC_TYPE_OTHER,
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  documentType: PropTypes.string,
  isImage: PropTypes.string,
  required: PropTypes.bool,
  showCaption: PropTypes.bool,
  error: PropTypes.shape({}),
  customUser: PropTypes.shape({}),
};

export const HybridFileInput = (props) => {
  const { onChange, label, name, error, value, errorNameKey, ...rest } = props;

  const onFileChange = (info) => {
    onChange(info.fileList);
  };

  let errorKey = name;

  if (errorNameKey) {
    errorKey = errorNameKey;
  }

  return (
    <div className="form-group field-user-cin">
      {error && error[errorKey] && <Error message={error[errorKey]} />}
      <label>
        {label} {rest.required && <span className="text-danger">*</span>}
      </label>
      <div style={{ display: "block" }}>
        <Upload
          name="file"
          onChange={onFileChange}
          fileList={value}
          accept={(f) => f}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
    </div>
  );
};

HybridFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.shape({}),
};

export default FileInput;
