import { useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";

const useAutoLogout = (onLogout, timeout) => {
    const loggedIn = localStorage.getItem("token") !== null;
    const { addToast, removeToast } = useToasts();
    const timeoutRef = useRef(null);
    const warningTimeoutRef = useRef(null);
    const [warningToastId, setWarningToastId] = useState(null);

    const warningMessage = () => {
        // Show the toast only if not already shown
        if (!warningToastId) {
            const id = addToast(
                "Your session is about to expire. Please stay active to remain logged in.",
                { appearance: "warning", autoDismiss: false }
            );
            setWarningToastId(id);
        }
    };

    const resetTimer = () => {
        clearTimeout(warningTimeoutRef.current);
        clearTimeout(timeoutRef.current);

        // Set a timeout to show the warning message
        warningTimeoutRef.current = setTimeout(warningMessage, timeout - 30000);

        // Set a timeout for logout
        timeoutRef.current = setTimeout(() => {
            if (warningToastId) {
                removeToast(warningToastId);
                setWarningToastId(null);
            }
            onLogout();
        }, timeout);
    };

    const handleUserActivity = () => {
        // Remove the warning toast if it's active
        if (warningToastId) {
            removeToast(warningToastId);
            setWarningToastId(null);
        }

        // Reset the inactivity timer
        resetTimer();
    };

    useEffect(() => {
        if (loggedIn) {
            // Add event listeners for user activity
            window.addEventListener("mousemove", handleUserActivity);
            window.addEventListener("keydown", handleUserActivity);
            window.addEventListener("scroll", handleUserActivity);
            window.addEventListener("click", handleUserActivity);

            // Initialize the timer
            resetTimer();
        }

        return () => {
            // Cleanup event listeners and timeouts on unmount or when logged out
            clearTimeout(warningTimeoutRef.current);
            clearTimeout(timeoutRef.current);
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("keydown", handleUserActivity);
            window.removeEventListener("scroll", handleUserActivity);
            window.removeEventListener("click", handleUserActivity);
        };
    }, [loggedIn, onLogout, timeout, warningToastId]); // Depend on loggedIn

    return null;
};

export default useAutoLogout;
