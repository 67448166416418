import {
  displayDocuments,
} from "../../utils/helperUtil";

const useEnforcementAudit = () => {
  const demandNoticeLog = (item) => {
    return {
      issued_by: item?.invoice_details?.created_by_name,
      created_at: item?.invoice_details?.demand_notice_log?.invoice_details?.created_at
    }
  };

  const reminderNoticeLog = (item) => {
    return {
      issued_by: item?.invoice_details?.created_by_name,
      created_at: item?.invoice_details?.reminder_notice_log?.created_at
    }
  };

  const businessSealDetails = (item) => {
    return {
      seal_number: item?.invoice_details?.business_seals?.[0]?.seal_number,
      reason: item?.invoice_details?.business_seals?.[0]?.reason,
      enforcer_name: item?.invoice_details?.business_seals?.[0]?.enforcer_name,
      date_sealed: item?.invoice_details?.business_seals?.[0]?.created_at,
    }
  };

  const taxPayerEngagementDetails = (item) => {
    return {
      enforcer_name: item?.invoice_details?.engagements?.[0]?.enforcer_name,
      engaged_at: item?.invoice_details?.engagements?.[0]?.created_at,
      engagement_minutes: item?.invoice_details?.engagements?.[0]?.engagement_minutes,
      ...displayDocuments(item?.invoice_details?.engagements?.[0]?.engagement_attachments),
    }
  };

  const enforcementAuditTableData = (data) => [
    ...data.map((item) => {
      const row = {
        notice_type: item?.notice_type_display,
        enforcer: item?.enforcer_name,
        created_at: item?.created_at
      };
      return row;
    }),
  ];

  const enforcementAuditTableColumns = () => [
    { id: "notice_type", header: "Notice Type" },
    { id: "enforcer", header: "Enforcer Name" },
    { id: "created_at", header: "Created At" }
  ];

  return {
    demandNoticeLog,
    reminderNoticeLog,
    businessSealDetails,
    taxPayerEngagementDetails,
    enforcementAuditTableColumns,
    enforcementAuditTableData,
  };
};


export default useEnforcementAudit;
