import React from "react";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import Error from "./Error";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = (props) => {
  const {
    onChange,
    value,
    label,
    error,
    name,
    validatePhoneNumber,
    errorNameKey,
    ...rest
  } = props;
  let errorKey = name;

  if (errorNameKey) {
    errorKey = errorNameKey;
  }
  return (
    <div className="form-group field-user-cin">
      {error && error[errorKey] && <Error message={error[errorKey]} />}
      <label>
        {label} {rest.required && <span className="text-danger">*</span>}
      </label>
      <PhoneInput
        value={value}
        onChange={onChange}
        name={name}
        country="ug"
        inputStyle={{ width: "100%" }}
        inputProps={{
          maxLength: 16,
        }}
        isValid={(value, country) => {
          if (value.length !== 12) {
            if (validatePhoneNumber) {
              validatePhoneNumber(false);
            }
            return "Invalid phone number";
          } else {
            if (validatePhoneNumber) {
              validatePhoneNumber(true);
            }
            return true;
          }
        }}
        countryCodeEditable={false}
        enableSearch
        disableSearchIcon
        // eslint-disable-next-line
        {...rest}
      />
    </div>
  );
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.shape({}),
};

export default PhoneNumberInput;
